import { createRouter, createWebHistory } from "@ionic/vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    redirect: "/init",
  },
  {
    path: "/init",
    name: "Init",
    component: () => import("@/views/Init.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isInitialized = store.state.global.isInitialized;
  const isAuth = store.state.global.isAuth;

  if (isInitialized === false && to.name !== "Init") {
    next({ name: "Init" });
  } else if (isInitialized === false && to.name === "Init") {
    next();
  } else if (to.name !== "Login" && isAuth === false) {
    next({ name: "Login" });
  } else if (to.name === "Login" && isAuth === true) {
    next({ name: "Home" });
  } else {
    next();
  }
});

export default router;
